
.checkedLabel{
	margin-left: 6px;
	margin-right: 6px;
}

.setPasswordfield{
	display: none;
}


.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    list-style: none;
}

.nvstep{
	display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}

.nvstep:after{
	background-color: #ccc;
	content: "";
	display: block;
	height: 1px;
	position: absolute;
	width: 100%;
	top: 32px;
}

.step{
	 background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    color: #ccc;
    font-size: 24px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
}

.nvstep.active >.step {            
    border-color: hsl(320deg 32% 50%);
    color: hsl(320deg 32% 50%);
}

.nvstep.active >.caption { 
	color: hsl(320deg 32% 50%);
}
.nvstep.complete>.step{
	border-color: hsl(320deg 32% 50%);
	color: hsl(320deg 32% 50%);
}

.nvstep.active:after {         
              background-color: hsl(320deg 32% 50%);
		}



.caption {
        color: #ccc;
        padding: 11px 16px;
    }
 

 .badge{
 	margin-right: 5px;
 }

 .badges{
 	text-align: end;
 } 

 .setpermission_row{
 	background: hsl(47deg 100% 57% / 36%);
 } 

 .setpermission-head-row{
 	background-color: #e7e4e433;
 }   


.invalid{
    background-color: #ff000014 !important;
    border-color: #ff00006b !important;
    border:1px solid #ff00006b !important;

}

.invalid-msg{
    color:#ff00006b;
}

.icon-rotation{
    transform: rotate(180deg)
}

.piechart_custom svg{
    width:80% !important;
    height:50% !important;
}




/*tooltip css*/

.tooltip {
  position: relative;
  border-bottom: 1px dotted black;
}

/* Tooltip box */
.tooltip:before {
  content: attr(data-tooltip); 
  position: absolute;
  width: 100px;
  background-color: #062B45;
  color: #fff;
  text-align: center;
  padding: 10px;
  line-height: 1.2;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  font-size: 0.75em;
  visibility: hidden;
}

/* Tooltip arrow */
.tooltip:after {
  content: "";
  position: absolute;
  bottom: 75%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  opacity: 0;
  transition: opacity .6s;
  border-color: #062B45 transparent transparent transparent;
  visibility: hidden;
}

.tooltip:hover:before, .tooltip:hover:after {
  opacity: 1;
  visibility: visible;
}




.btn-custom{
    width:30px;
    height: 30px;
    padding: 0px;
}

.btn-custom i{
    font-size:14px;
}

.connectiondevice{
        border: 1px solid #fdf9c5;
    border-radius: 6px;
    font-size: 18px;
    padding: 12px 30px 12px 30px;
    background: #fdf9c5;
    font-weight: 700;
}


.app-activate-process-img img{
    width: 100%;
    height: 500px;
    border-radius: 10px;
    border: 1px solid #ffaaf5;
    box-shadow: 0px 1px 4px 0px #ffaaf5;
}


.devider{
    font-weight: 700;
    font-size: 22px;
}

.devider::before{
 content: "-";
 width:50px;
}

.devider::after{
    content: "-";
    width:50px;
}

.css-b62m3t-container{
    width:100%;
}


/*---------css loader--------*/

.loader {
   /* control the size */  
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0003,#000);
          mask: conic-gradient(from 15deg, #0003,#000);
  animation: load 1s steps(12) infinite;
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(circle closest-side ,currentColor 90%,#0000 98%) 
    50% -150%/20% 80% repeat-y,
    radial-gradient(circle closest-side ,currentColor 90%,#0000 98%) 
    -150% 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after{
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after{
  transform: rotate(60deg);
}
@keyframes load {
  from {transform: rotate(0turn)}
  to   {transform: rotate(1turn)}
}


/*-----pricing plan------*/

.wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 15px;
    margin: 50px;
    padding: 0px 20px;

}
.pricing-table{
   box-shadow: 0px 0px 18px #ccc;
   text-align: center;
   padding: 30px 0px;
   border-radius: 5px;
   position: relative;
 
}
.pricing-table .head {
    border-bottom:1px solid #eee;
    padding-bottom: 50px;
    transition: all 0.5s ease;
}
.pricing-table:hover .head{
   border-bottom:1px solid #8E2DE2;
   
}

.pricing-table .head .title{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
}

.pricing-table .content .price{
    background: linear-gradient(to right, #a8578d 0%, #a8578d 100%);
    width: 90px;
    height: 90px;
    margin: auto;
    line-height: 90px;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px #ccc;
    margin-top: -50px;
     transition: all 0.5s ease;
     display: flex;
    justify-content: center;
    align-items: center;
}
.pricing-table:hover .content .price{
    transform: scale(1.2);
 
}
.pricing-table .content .price h1{
    color:#fff;
    font-size: 22px;
    font-weight: 500;
}
.pricing-table .content ul{
   list-style-type: none;
   margin-bottom: 20px;
   padding-top: 10px;
   padding-left: 0px;
}

.pricing-table .content ul li{
    margin: 20px 0px;
    font-size: 14px;
    color:#555;
}


.pricing-table .btn.bordered {
    z-index: 50;
    color: #333;
}
.pricing-table:hover .btn.bordered{
    color:#fff !important;
}

.pricing-table .btn.bordered:after {
    background: #fff none repeat scroll 0 0;
    border-radius: 50px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3 linear;
    transition: all 0.3 linear;
    width: 100%;
    z-index: -1;    
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
    transform:scale(1);
}
.pricing-table:hover .btn.bordered:after{
    opacity:0;
    transform:scale(0);
}

@media screen and (max-width:768px){
   .wrapper{
        grid-template-columns: repeat(2,1fr);
    } 
}

@media screen and (max-width:600px){
   .wrapper{
        grid-template-columns: 1fr;
    } 
}
.active-label {    
    color: white;
    width: 141px;
    display: inline-block;
    height: 28px;
    position: absolute;
    left: 50%;
    top: 0px;
    border-radius: 3px;
    transform: translate(-50%, -50%);
}
.active-label p{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 26px;
}

.activate{
    background-color: #198754;
}

.deactivate{
    background-color: #dc3545;
}
.filter-month{
    border-bottom: 1px solid;    
}

.custom-month-list{   
    margin-top:10px;
    margin-right: 5px; 
    display: flex;
    text-align: center;
    width: 100%;  
}

.custom_pading_list{
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #000;
    padding: 0px 25px 3px;
    width: 100%;
}
.filter-by-month{
    border: 1px solid #f1f1f1;
    padding: 10px;
    border-radius: 5px;
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item a{
    font-size: 15px;
    font-weight: 400;
    padding: 5px 10px;
}